.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin: 1rem; /* Using rem for consistent spacing */
}

.start-header {
  max-width: 30rem;
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.header-image {
  width: 100%;
  transition: transform 0.2s ease;
  display: block; /* Ensure the image is treated as a block element */
  object-fit: contain; /* Maintain aspect ratio while fitting within the box *
  /* max-width: 30rem; Using rem for max width */
}

.button-image {
  width: 18rem; /* Using rem for width */
  height: auto;
  transition: transform 0.2s ease;
}

.image-button:hover .button-image {
  transform: scale(1.1);
}

.image-button:hover .header-image {
  transform: scale(1.1);
}

.header {
  max-width: 25rem;
  margin: 1rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.characters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  margin: 2rem 0; /* Using rem for consistent margin */
}

.characters > div {
  width: 20%; /* Adjust width for better responsiveness */
  min-width: 7rem; /* Ensure a minimum size */
  justify-content: center;
  display: flex;
}

.characters img {
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure images fit within their containers */
}

.book {
  margin: auto 1rem; /* Using rem for consistent margin */
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}

.box-placeholder {
  position: relative;
  height: auto; /* Using rem for height */
  margin-bottom: 1rem; /* Using rem for spacing */
}

.box-placeholder .counter {
  position: absolute;
  bottom: -1rem;  
  left: 50%;
  transform: translateX(-50%);
}

.counter {
  width: 8rem !important; /* Using rem for width */
}

.cardContainer {
  width: 12rem; /* Using viewport width */
  position: relative;
}

.card, .cardContainer {
  max-width: fit-content;
}

.swipe {
  display: grid;
}

.swipe img {
  width: 100%; /* Ensure images are responsive */
}

.card {
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
  border-radius: 1.25rem; /* Using rem for border radius */
  box-shadow: 0 0 4rem rgba(0, 0, 0, .3); /* Using rem for shadow */
}

.gauge-container {
  position: relative;
  margin: 1rem auto; /* Using rem for margin */
  width: 100%;
  max-width: 25rem; /* Using rem for max width */
  height: auto; /* Allow height to adjust */
}

.gauge-container > * {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gauge-container img {
  width: 100%;
  height: auto;
}

.arrow {
  position: absolute;
  top: 45%; /* Adjust for better centering */
  left: 50%;
  height: 80%; /* Using rem for height */
  width: 15%;
  transform-origin: center bottom;
}

.arrow-message {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, 50%) rotate(0deg);  
}

.actions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap; /* Allow wrapping */
}

.actions img {
  height: 5rem;
  width: auto;
}

.image-button {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.devil .button-image{
  height: 15rem;
  width: auto;
}

.angel .button-image{
  height: 15rem;
  width: auto;
}

.devil {
  margin-right: 1rem;
}

.angel{
  margin-left: 1rem;
}

.finish-gif{
  width: 20rem;
  height: auto;
}

/* Mobile Portrait */
@media only screen and (max-width: 768px) {
  .App {
    margin: 2%; /* Using percentage for margin */
  }

  .start-header{
    margin: 1rem;
    padding: 1rem;
  }

  .header{
    margin-bottom: 0;
  }

  .header-image {
    max-width: 13rem; /* Adjusted for smaller screens */
  }

  .start-header{
    margin: 1rem;
    padding: 1rem;
  }

  .characters{
    margin: 1rem 0;
  }

  .cardContainer {
    width: 11rem;
  }

  .box-placeholder .counter {
    bottom: -0.75rem;
  }

  .counter {
    max-width: 6rem !important;
  }

  .characters > div {
    width: 30%; /* More space on smaller screens */
  }

  .gauge-container {
    margin: 0.5rem auto;
    max-width: 13rem;
  }

  .actions img {
    height: 3rem; /* Reduced height */
  }

  .devil .button-image{
    height: 12rem;
    width: auto;
  }
  
  .angel .button-image{
    height: 12rem;
    width: auto;
  }
}
